import { useState } from "react";
import useSWR from "swr";
import ResizeDetector from "react-resize-detector";
import { Box, Stack, Typography as T } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import HeaderLogo from "~/components/HeaderLogo";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const pdfFetcher = async (url: string) => {
  const res = await fetch(url);

  if (!res.ok) {
    const error: any = new Error();
    const resp = await res.json();
    error.message = JSON.stringify(resp);
    error.status = res.status;
    throw error;
  }

  return res.blob();
};

const PrivacyPolicy = () => {
  const [numPages, setNumPages] = useState<null | number>(null);
  const { data } = useSWR(`/assets/privacy.pdf`, pdfFetcher, {
    revalidateOnFocus: false,
  });

  return (
    <Stack
      height="100%"
      minHeight="100vh"
      bgcolor="#FFF"
      alignItems="center"
      py={10}
      minWidth={500}
    >
      <HeaderLogo />
      <T variant="h4" component="h1" fontWeight={600} zIndex={1}>
        Privacy Policy
      </T>
      <Box width="100%" my={-6} maxWidth={1000}>
        <ResizeDetector handleWidth>
          {({ width }) => (
            <Document
              file={data}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              noData={<></>}
              loading={<></>}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  width={width}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderAnnotationLayer={false}
                  noData={<></>}
                  loading={<></>}
                />
              ))}
            </Document>
          )}
        </ResizeDetector>
      </Box>
    </Stack>
  );
};

export default PrivacyPolicy;
