import { ComponentProps, FunctionComponent, useMemo } from "react";
import pluralize from "pluralize";
import { Link as RouterLink } from "react-router-dom";
import {
  Alert,
  Box,
  CardContent,
  Container,
  Link,
  Skeleton,
  Stack,
  Typography as T,
  TypographyVariant,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import InteractiveCard, { IconContainer } from "~/components/InteractiveCard";
import useCatalogEnabled, { useSemanticCatalogEnabled } from "catalog/hooks/useCatalogEnabled";
import useCatalogSearch from "catalog/hooks/useCatalogSearch";
import { TableEntity, VariableEntity } from "catalog/types/interfaces/catalogResponse";
import { DataOverview, DatasetIcon, FilesToolIcon, PinIcon, VariableIcon } from "~/assets/icons";
import usePinnedEntities from "catalog/hooks/usePinnedEntities";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";
import useAppHeader from "~/contexts/header";
import useUser from "~/contexts/user";
import useConceptCategories from "catalog/hooks/useConceptCategories";
import ConceptCategory, { DISPLAY_CONCEPT_CATEGORY } from "catalog/types/enums/conceptCategory";
import { DISPLAY_CONCEPT_ENTITY } from "catalog/types/enums/conceptEntity";

interface TileProps {
  label: string;
  description: string;
  href: string;
  icon?: FunctionComponent<ComponentProps<"svg">>;
  iconColor?: string;
  backgroundColor?: string;
}

const numFormatter = Intl.NumberFormat("en", { notation: "compact" });

const useDataBrowserSections = () => {
  const user = useUser();
  const catalogEnabled = useCatalogEnabled();
  const semanticCatalogEnabled = useSemanticCatalogEnabled();
  const {
    data: tables,
    isLoading: loadingTables,
    error: tableError,
  } = useCatalogSearch<TableEntity>("table");
  const {
    data: columns,
    isLoading: loadingColumns,
    error: columnError,
  } = useCatalogSearch<VariableEntity>("column");
  const { total: totalPinned } = usePinnedEntities();
  const {
    data: conceptCategories,
    loading: loadingConcepts,
    error: conceptsError,
  } = useConceptCategories();

  const loading = !user || loadingTables || loadingColumns || loadingConcepts;
  const tablesTotal = Number(tables?.hits?.total?.value);
  const columnsTotal = Number(columns?.hits?.total?.value);

  const sections = useMemo(() => {
    const result: { title: string; tiles: TileProps[] }[] = [];

    const reportTiles = [];

    if (import.meta.env.VITE_DATA_DASHBOARD_URL) {
      reportTiles.push({
        href: "/data-overview",
        label: "Overview",
        description: "Top level summary of organization data",
        icon: DataOverview,
        iconColor: "#2225F5",
        backgroundColor: "#F2F5FF",
      });
    }

    if (conceptCategories && semanticCatalogEnabled) {
      conceptCategories.forEach(({ category, counts }) => {
        const categoryDisplay = DISPLAY_CONCEPT_CATEGORY[category as ConceptCategory];
        if (categoryDisplay && counts.length) {
          const formattedCount = counts
            .map(({ type, count }) => {
              return `${numFormatter.format(count)} ${pluralize(
                DISPLAY_CONCEPT_ENTITY[type],
                count
              )}`;
            })
            .join(" · ")
            .toLowerCase();

          reportTiles.push({
            href: `/catalog/concepts/${category}`,
            label: categoryDisplay.title,
            description: formattedCount,
            icon: categoryDisplay.icon,
            iconColor: categoryDisplay.iconColor,
            backgroundColor: categoryDisplay.backgroundColor,
          });
        }
      });
    }

    if (reportTiles.length) {
      result.push({ title: "Reports", tiles: reportTiles });
    }

    const assetTiles: TileProps[] = [
      {
        href: "/files",
        label: "Files",
        description: "Personal, project and organization files",
        icon: FilesToolIcon,
      },
    ];

    if (catalogEnabled) {
      if (tablesTotal) {
        assetTiles.push({
          href: "/catalog/tables",
          label: "Tables",
          description: `${numFormatter.format(tablesTotal)} tables`,
          icon: DatasetIcon,
        });
      }

      if (columnsTotal) {
        assetTiles.push({
          href: "/catalog/columns",
          label: "Columns",
          description: `${numFormatter.format(columnsTotal)} columns`,
          icon: VariableIcon,
        });
      }

      assetTiles.push({
        href: "/catalog/pins",
        label: "Pins",
        description: `${numFormatter.format(totalPinned)} pinned`,
        icon: PinIcon,
      });
    }

    result.push({
      title: "Data Assets",
      tiles: assetTiles,
    });

    return result;
  }, [catalogEnabled, semanticCatalogEnabled, conceptCategories, tablesTotal, columnsTotal]);

  return { sections, loading, error: Boolean(tableError || columnError || conceptsError) };
};

const DataBrowserTile = ({
  href,
  icon: Icon,
  iconColor,
  backgroundColor,
  label,
  description,
}: TileProps) => {
  return (
    <Link component={RouterLink} underline="none" to={href}>
      <InteractiveCard>
        <CardContent sx={{ minHeight: 176, px: 3, pt: 3, gap: 3 }}>
          {Icon && (
            <IconContainer bgcolor={backgroundColor ?? "#F2F2F4"}>
              <Icon color={iconColor ?? "#31323C"} />
            </IconContainer>
          )}
          <Stack>
            <T variant="body1" fontWeight={500}>
              {label}
            </T>
            <T
              variant="body2"
              sx={{
                opacity: 0.7,
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "2",
              }}
            >
              {description}
            </T>
          </Stack>
        </CardContent>
      </InteractiveCard>
    </Link>
  );
};

const Loading = () => (
  <Stack gap={1}>
    <Skeleton variant="text" width={100} height={35} />
    <Grid container spacing={2}>
      {Array.from({ length: 6 }).map((_, i) => (
        <Grid key={i} xs={6} sm={4}>
          <Skeleton variant="rounded" height={175} />
        </Grid>
      ))}
    </Grid>
  </Stack>
);

export const DataBrowserSections = ({
  titleTypography = "h6",
}: {
  titleTypography?: TypographyVariant;
}) => {
  const { sections, loading, error } = useDataBrowserSections();

  if (loading && !error) {
    return <Loading />;
  }
  return (
    <Stack gap={4}>
      {error && (
        <Alert severity="error" variant="outlined">
          Unable to load data
        </Alert>
      )}
      {sections.map((section) => (
        <Stack key={section.title} gap={2}>
          {sections.length > 1 && <T variant={titleTypography}>{section.title}</T>}
          <Grid container spacing={2}>
            {section.tiles.map((props) => (
              <Grid key={props.label} xs={6} sm={3}>
                <DataBrowserTile {...props} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      ))}
    </Stack>
  );
};

const title = "Data Browser";

const DataBrowser = () => {
  useDocumentTitle(title);
  useAppHeader({ title });

  return (
    <Box bgcolor="common.dimmedPageBackground" minHeight="100%">
      <Container maxWidth="md" sx={{ py: 3 }}>
        <DataBrowserSections />
      </Container>
    </Box>
  );
};

export default DataBrowser;
