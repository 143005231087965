import yn from "yn";
import { PropsWithChildren } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Container,
  Divider,
  dividerClasses,
  Link,
  Skeleton,
  Stack,
  Typography as T,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import {
  CampaignToolIcon,
  ParticipantSearchToolIcon,
  PlusIcon,
  SurveyToolIcon,
  TaskToolIcon,
} from "~/assets/icons";
import useUser from "~/contexts/user";
import useAppHeader from "~/contexts/header";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";
import BasicButton from "~/components/BasicButton";
import InteractiveCard from "~/components/InteractiveCard";
import { DataBrowserSections } from "~/pages/DataBrowser";
import useProjects from "rwb/hooks/useProjects";
import CreateProject from "rwb/pages/projects/components/CreateProject";
import ProjectList from "rwb/pages/projects/components/ProjectList";

const { VITE_RWB_ENABLED, VITE_SM_ENABLED } = import.meta.env;

const Section = (props: { title: string } & PropsWithChildren) => (
  <Box
    component="section"
    sx={{
      pt: 2.5,
      ":last-of-type": {
        pb: 5,
        [`.${dividerClasses.root}`]: {
          display: "none",
        },
      },
    }}
  >
    <Container>
      <T variant="h6" fontWeight={550}>
        {props.title}
      </T>
      {props.children}
      <Divider flexItem sx={{ mt: 3.5, borderColor: "rgba(0,0,0,0.08)" }} />
    </Container>
  </Box>
);

const PROJECTS_LIMIT = 4;

const WorkbenchWidget = () => {
  const projects = useProjects(PROJECTS_LIMIT);
  const loading = projects.isLoading;
  const empty = !loading && !projects.data?.total;

  return (
    <Section title="Workbench">
      {!empty && (
        <Box display="flex" gap={1} mt={2} mb={1}>
          {loading ? (
            <>
              <Skeleton variant="rounded" width={100} height={35} />
              <Skeleton variant="rounded" width={120} height={35} />
            </>
          ) : (
            <>
              {Number(projects.data?.total) > PROJECTS_LIMIT && (
                <BasicButton component={RouterLink} to="/projects">
                  All Projects
                </BasicButton>
              )}
              <CreateProject
                callToAction={<BasicButton startIcon={<PlusIcon />}>New Project</BasicButton>}
              />
            </>
          )}
        </Box>
      )}
      <ProjectList data={projects} pageSize={PROJECTS_LIMIT} showPagination={false} />
    </Section>
  );
};

const DataBrowserWidget = () => {
  return (
    <Section title="Data Browser">
      <DataBrowserSections titleTypography="subtitle2" />
    </Section>
  );
};

const StudyManagementWidget = () => {
  const links = [
    {
      url: "/sm/participants",
      icon: <ParticipantSearchToolIcon />,
      title: "Search Participants",
      description:
        "Look up participants to view detailed information about their demographics and study participation.",
    },
    {
      url: "/sm/surveys",
      icon: <SurveyToolIcon />,
      title: "Manage Surveys",
      description: "Build a survey to send to participants.",
    },
    {
      url: "/sm/outreach",
      icon: <CampaignToolIcon />,
      title: "Contact Participants",
      description: "Send surveys or other study-related information to a group of participants.",
    },
    {
      url: "/sm/tasks",
      icon: <TaskToolIcon />,
      title: "Manage Tasks",
      description:
        "Manage tasks related to participants' self-reported cancers, revocations or specific study information.",
    },
  ];

  return (
    <Section title="Study Management">
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {links.map((link) => (
          <Grid key={link.url} sm={6} md={3} sx={{ width: "100%" }}>
            <Link component={RouterLink} to={link.url} underline="none">
              <InteractiveCard
                sx={{ p: 3, minHeight: ["none", 210], justifyContent: "space-between" }}
              >
                <Box width={30} height={30}>
                  {link.icon}
                </Box>
                <T variant="body1" fontWeight={500} mt={2} mb={2}>
                  {link.title}
                </T>
                <T
                  variant="body2"
                  sx={{
                    opacity: 0.7,
                    flex: 1,
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: "4",
                  }}
                >
                  {link.description}
                </T>
              </InteractiveCard>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

const title = "Home";

const Home = () => {
  const user = useUser();
  useDocumentTitle(title);
  useAppHeader({ title });
  const showDb = !!user;
  const showRwb = yn(VITE_RWB_ENABLED) && user?.permissions?.includes("view:workbench-app");
  const showSm = yn(VITE_SM_ENABLED) && user?.permissions?.includes("view:sm-app");

  return (
    <Stack bgcolor="common.dimmedPageBackground" minHeight="100%">
      {showSm && <StudyManagementWidget />}
      {showRwb && <WorkbenchWidget />}
      {showDb && <DataBrowserWidget />}
    </Stack>
  );
};

export default Home;
