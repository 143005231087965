import { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { KeyedMutator } from "swr";
import {
  Avatar,
  avatarClasses,
  AvatarGroup,
  Box,
  Link,
  MenuItem,
  menuItemClasses,
  Typography as T,
} from "@mui/material";
import StopIcon from "@mui/icons-material/Stop";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Project from "rwb/types/interfaces/project";
import { formatRelative } from "date-fns";
import { EnvironmentsStatus } from "rwb/pages/projects/components/EnvironmentsStatus";
import ActionsMenu from "~/components/ActionsMenu";
import { DeleteProjectModal } from "rwb/components/DeleteProjectModal";
import useProjectAccess from "rwb/hooks/useProjectAccess";
import ProjectPrivilege from "rwb/pages/project/types/enums/privilege";
import InteractiveCard from "~/components/InteractiveCard";
import EnvironmentStatus from "rwb/pages/project/types/enums/environmentStatus";
import { ProjectsResponse } from "rwb/hooks/useProjects";

const { VITE_RWB_API } = import.meta.env;

type ProjectCardProps = {
  data: Project;
  mutateProjects: KeyedMutator<ProjectsResponse>;
};

const formatLastAccessedAt = (lastAccessedAt: string | null) => {
  if (lastAccessedAt) {
    const date = new Date(lastAccessedAt);
    const relativeFormat = formatRelative(date, new Date());
    const relativeWithoutTime = relativeFormat.split(" at ")[0];
    return `Last used ${relativeWithoutTime}`;
  }
};

const ProjectCard = ({ data, mutateProjects }: ProjectCardProps) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const { id, title, environments, last_accessed_at } = data;
  const canDelete = useProjectAccess(ProjectPrivilege.DELETE_PROJECT, data);
  const canManageCompute = useProjectAccess(ProjectPrivilege.MANAGE_COMPUTE, data);

  const handleStopEnvs = async () => {
    const resp = await fetch(`${VITE_RWB_API}/environments?project_id=${id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ state: EnvironmentStatus.STOPPED, project_id: data.id }),
    });

    if (resp.ok) {
      await mutateProjects();
    }
  };

  const menuItems = useMemo(() => {
    const result = [];
    if (canManageCompute) {
      const hasRunningEnvs = data.environments?.some(
        (env) => env.status === EnvironmentStatus.RUNNING
      );

      if (hasRunningEnvs) {
        result.push(
          <MenuItem key="stop-envs" onClick={handleStopEnvs}>
            <StopIcon fontSize="small" sx={{ mr: 1 }} />
            Stop All Environments
          </MenuItem>
        );
      }
    }
    if (canDelete) {
      result.push(
        <MenuItem
          key="delete"
          sx={{
            color: "attention.main",
            [`&.${menuItemClasses.root}:hover`]: {
              color: "common.white",
              bgcolor: "attention.main",
            },
          }}
          onClick={() => setDeleteModalOpen(true)}
        >
          <DeleteOutlineOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      );
    }
    return result;
  }, [data, canDelete, canManageCompute]);

  return (
    <>
      <Link
        data-testid="project-card"
        to={`/projects/${id}`}
        component={RouterLink}
        underline="none"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: "1 1 auto",
        }}
      >
        <InteractiveCard
          onMouseEnter={() => {
            if (menuItems.length) {
              setShowActions(true);
            }
          }}
          onMouseLeave={() => {
            if (menuItems.length) {
              setShowActions(false);
            }
          }}
          sx={{ px: 3, pt: 1.5, pb: 3 }}
        >
          <Box display="flex" alignItems="center" flex="0" justifyContent="space-between">
            {/**
             * Collaborators
             */}
            <AvatarGroup max={5} sx={{ [`& .${avatarClasses.root}`]: { width: 30, height: 30 } }}>
              {data.members.map(({ member_identity }) => (
                <Avatar
                  key={member_identity.email}
                  alt={member_identity.name ?? member_identity.email}
                  src={member_identity.picture}
                />
              ))}
            </AvatarGroup>
            {/**
             * Meatball Menu
             */}
            <Box position="absolute" right={12}>
              <ActionsMenu visible={showActions} menuItems={menuItems} />
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" flex="1" gap={0.75}>
            {/**
             * Title
             */}
            <Box pt={1}>
              <T
                className="title"
                sx={{
                  lineHeight: 1.2,
                  fontWeight: 500,
                  fontSize: 16,
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: "2",
                  minHeight: 40,
                }}
              >
                {title}
              </T>
            </Box>
            {/**
             * Environments
             */}
            <Box display="flex" alignItems="center" minHeight={56}>
              {canManageCompute && (
                <EnvironmentsStatus
                  projectId={id}
                  environments={environments}
                  mutateProjects={mutateProjects}
                />
              )}
            </Box>
            {/**
             * Last update timestamp
             */}
            <T variant="caption" fontSize={13} color="grey.700" minHeight={22}>
              {formatLastAccessedAt(last_accessed_at)}
            </T>
          </Box>
        </InteractiveCard>
      </Link>
      <DeleteProjectModal
        project={data}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      />
    </>
  );
};

export default ProjectCard;
